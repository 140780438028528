<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">Change Password</h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Change your account password</span
        >
      </div>
      <div class="card-toolbar">
        <button
          :class="apiBusy ? buttonSpinner : ''"
          type="submit"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Save Changes
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          Cancel
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form" id="kt_password_change_form">
      <div class="card-body">
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert"
            >Current Password</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              type="password"
              class="form-control form-control-lg form-control-solid mb-2"
              value=""
              placeholder="Current password"
              name="current_password"
              ref="current_password"
            />
          </div>
        </div>
        <div class="separator separator-dashed my-10"></div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert"
            >New Password</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              type="password"
              class="form-control form-control-lg form-control-solid"
              value=""
              placeholder="New password"
              name="new_password"
              ref="new_password"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert"
            >Verify Password</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              type="password"
              class="form-control form-control-lg form-control-solid"
              value=""
              placeholder="Enter password again"
              name="verify_password"
              ref="verify_password"
            />
          </div>
        </div>
      </div>
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { UPDATE_PASSWORD } from "@/core/services/store/user.module";
import KTUtil from "@/assets/js/components/util";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Swal from "sweetalert2";

export default {
  name: "ChangePassword",
  data() {
    return {
      password: "",
      status: "",
      valid: true
    };
  },
  computed: {
    ...mapGetters(["currentUser", "apiBusy", "buttonSpinner"])
  },
  mounted() {
    const password_change_form = KTUtil.getById("kt_password_change_form");

    this.fv = formValidation(password_change_form, {
      fields: {
        current_password: {
          validators: {
            notEmpty: {
              message: "Current password is required"
            }
          }
        },
        new_password: {
          validators: {
            notEmpty: {
              message: "New password is required"
            }
          }
        },
        verify_password: {
          validators: {
            notEmpty: {
              message: "Confirm password is required"
            },
            identical: {
              compare: function() {
                return password_change_form.querySelector(
                  '[name="new_password"]'
                ).value;
              },
              message: "The password and its confirm are not the same"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap(),
        submitButton: new SubmitButton()
      }
    });
  },

  methods: {
    ...mapActions(["toast"]),
    save() {
      this.fv.validate();

      this.fv.on("core.form.valid", () => {
        var newPassword = this.$refs.new_password.value;
        var currentPassword = this.$refs.current_password.value;

        const submitButton = this.$refs["kt_save_changes"];

        // set spinner to submit button
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay

        // send update request
        this.$store
          .dispatch(UPDATE_PASSWORD, {
            ID: this.currentUser.ID,
            current_password: currentPassword,
            new_password: newPassword
          })

          // go to which page after successfully update
          .then(() => {
            this.toast("Password successfully changed");
            this.cancel();
          })

          .catch(error => {
            this.toast(
              "There was an error while updating the password | " +
                error.message
            );
          });
      });

      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary"
        });
      });
    },
    cancel() {
      this.fv.resetForm();
      this.$refs.current_password.value = "";
      this.$refs.new_password.value = "";
      this.$refs.verify_password.value = "";
    }
  }
};
</script>

<style scoped></style>
