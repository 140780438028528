<template>
  <div class="d-flex flex-row">
    <div class="flex-row-auto offcanvas-mobile w-300px w-xl-350px" id="kt_profile_aside">
      <div class="card card-custom card-stretch">
        <div class="card-body pt-4">
          <!-- <div class="d-flex justify-content-end">
            <KTDropdown2></KTDropdown2>
          </div> -->
          <div class="d-flex align-items-center">
            <div class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
              <div
                class="symbol-label"
                :style="{
                  backgroundImage: `url(${currentUser.avatar})`
                }"
              ></div>
            </div>
            <div class="d-flex flex-column">
              <a class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{{ getFullName }}</a>
              <a class="text-muted text-hover-primary">{{ currentUser.user_email }}</a>
            </div>
          </div>
          <!--end::User-->
          <div class="separator separator-dashed my-10"></div>
          <!--begin::Nav-->
          <div class="navi navi-bold navi-hover navi-active navi-link-rounded" role="tablist">
            <!-- begin: nav personal info -->
            <div class="navi-item mb-2">
              <a
                class="navi-link py-4 active"
                @click="setActiveTab"
                href="#"
                data-tab="0"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg src="/media/svg/icons/General/User.svg" />
                  </span>
                </span>
                <span class="navi-text font-size-lg">Personal Information</span>
              </a>
            </div>
            <!-- end: nav personal info -->

            <!-- begin: nav change password -->
            <div class="navi-item mb-2">
              <a
                class="navi-link py-4"
                @click="setActiveTab"
                href="#"
                data-tab="1"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg src="/media/svg/icons/Communication/Shield-user.svg" />
                  </span>
                </span>
                <span class="navi-text font-size-lg">Change Password</span>
              </a>
            </div>
            <!-- end: nav change password -->

            <!-- begin:  billing details -->
            <div class="navi-item mb-2">
              <a v-b-tooltip.hover title="Coming Soon" class="navi-link py-4">
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg src="/media/svg/icons/Layout/Layout-top-panel-6.svg" />
                  </span>
                </span>
                <span class="navi-text font-size-lg">Billing Details</span>
              </a>
            </div>
            <!-- end: billing details -->

            <!-- begin:  account statement -->
            <div class="navi-item mb-2">
              <a v-b-tooltip.hover title="Coming Soon" class="navi-link py-4">
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg src="/media/svg/icons/Text/Article.svg" />
                  </span>
                </span>
                <span class="navi-text">Statements</span>
              </a>
            </div>
            <!-- end: account statement -->
          </div>
        </div>
      </div>
    </div>

    <!--begin::Content-->
    <div class="flex-row-fluid ml-lg-8 ">
      <b-tabs nav-wrapper-class="profile-tabs-wrapper" v-model="tabIndex">
        <b-tab active>
          <KTPersonalInformation></KTPersonalInformation>
        </b-tab>

        <b-tab>
          <KTChangePassword></KTChangePassword>
        </b-tab>

        <b-tab>
          <KTEmailSettings></KTEmailSettings>
        </b-tab>
      </b-tabs>
    </div>
    <!--end::Content-->
  </div>
</template>
<style>
.profile-tabs-wrapper {
  display: none;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
// import KTDropdown2 from "@/view/pages/profile/profile-comp/Dropdown2";
import KTPersonalInformation from "@/view/pages/profile/profile-comp/PersonalInformation";

import KTChangePassword from "@/view/pages/profile/profile-comp/ChangePassword";
import KTEmailSettings from "@/view/pages/profile/profile-comp/EmailSettings";

export default {
  name: "custom-page",
  components: {
    // KTDropdown2,
    KTPersonalInformation,

    KTChangePassword,
    KTEmailSettings
  },

  data() {
    return {
      tabIndex: 0
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Profile" }]);
  },

  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),

    getFullName() {
      return this.currentUser.display_name;
    }
  }
};
</script>
