<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Personal Information
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Update your personal informaiton</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          :class="apiBusy == true && uploading == false ? buttonSpinner : ''"
          class="btn btn-success mr-2"
          @click="save()"
        >
          Save Changes
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          Cancel
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form" id="personalInfoForm">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <label class="col-xl-3"></label>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Avatar</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="image-input image-input-outline" id="kt_profile_avatar">
              <div
                class="image-input-wrapper"
                :style="{ backgroundImage: `url(${photo})` }"
              ></div>
              <label
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="change"
                data-toggle="tooltip"
                title=""
                data-original-title="Change avatar"
              >
                <i class="fa fa-pen icon-sm text-muted"></i>
                <input
                  type="file"
                  name="profile_avatar"
                  accept=".png, .jpg, .jpeg"
                  @change="onFileChange($event)"
                />
                <input type="hidden" name="profile_avatar_remove" />
              </label>
              <span
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="cancel"
                data-toggle="tooltip"
                title="Cancel avatar"
              >
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
              <span
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="remove"
                data-toggle="tooltip"
                title="Remove avatar"
                @click="removeAvatar()"
              >
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
            </div>
            <span class="form-text text-muted"
              >Allowed file types: png, jpg, jpeg.</span
            >
            <div v-if="uploading" class="progress h-3px mt-2">
              <div
                class="progress-bar bg-primary"
                role="progressbar"
                :style="{ width: `${uploadProgress}%` }"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Full Name</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              name="name"
              ref="name"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-bind:value="currentUser.display_name"
            />
          </div>
        </div>

        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mt-10 mb-6">Contact Info</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Phone Number</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-phone"></i>
                </span>
              </div>
              <input
                name="phone"
                ref="phone"
                type="text"
                class="form-control form-control-lg form-control-solid"
                placeholder="Phone"
                v-bind:value="currentUser.user_phone"
              />
            </div>
            <span class="form-text text-muted"
              >We'll never share your email with anyone else.</span
            >
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Email Address</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-at"></i>
                </span>
              </div>
              <input
                name="email"
                ref="email"
                type="text"
                class="form-control form-control-lg form-control-solid"
                placeholder="Email"
                v-bind:value="currentUser.user_email"
              />
            </div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  UPDATE_USER,
  UPLOAD_AVATAR,
  REMOVE_AVATAR
} from "@/core/services/store/user.module";

import FormValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";
import Swal from "sweetalert2";

export default {
  name: "PersonalInformation",
  data() {
    return {
      default_photo: "media/users/blank.png",
      current_photo: null,
      uploading: false
    };
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "apiBusy",
      "buttonSpinner",
      "uploadProgress"
    ]),
    photo() {
      return this.currentUser.avatar;
    }
  },

  mounted() {
    this.current_photo = this.currentUser.avatar;

    // init validation
    const frmPersonalInfo = KTUtil.getById("personalInfoForm");
    this.fv = FormValidation(frmPersonalInfo, {
      fields: {
        name: {
          validators: {
            notEmpty: {
              message: "Name is requied"
            }
          }
        },
        email: {
          validators: {
            notEmpty: {
              message: "Email address is required"
            },
            emailAddress: {
              message: "Provide a valid email address"
            }
          }
        },
        phone: {
          validators: {
            numeric: {
              message:
                "Phone number must be numeric values only.  Avoid spaces or special characters."
            }
          }
        }
      },

      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap({})
      }
    });
  },
  methods: {
    ...mapActions(["toast"]),
    save() {
      this.fv.validate().then(status => {
        if (status == "Valid") {
          const name = this.$refs.name.value;
          const phone = this.$refs.phone.value;
          const email = this.$refs.email.value;
          const ID = this.currentUser.ID;

          // send update request
          this.$store
            .dispatch(UPDATE_USER, {
              ID,
              display_name: name,
              user_phone: phone,
              user_email: email
            })

            .then(() => {
              this.toast("Personal Information Successfully Updated");
            })

            .catch(error => {
              this.toast({
                title: "Something went wrong",
                content: error.message,
                variant: "danger"
              });
            });
        }
      });
    },

    cancel() {
      this.$refs.name.value = this.currentUser.display_name;
      this.$refs.phone.value = this.currentUser.user_phone;
      this.$refs.email.value = this.currentUser.user_email;

      this.fv.validate();
    },

    onFileChange(event) {
      // fetch files to uplaod from refs
      let filesToUpload = event.target.files;

      if (filesToUpload.length === 0) {
        return;
      }

      // set status to busy
      this.uploading = true;

      // avatarFile is the first file
      const file = filesToUpload[0];
      const data = {
        ID: this.currentUser.ID,
        file: file
      };

      this.$store
        .dispatch(UPLOAD_AVATAR, data)

        .then(() => {
          this.$store.dispatch("toast", {
            title: "upload succesful",
            content: "",
            variant: "success"
          });
        })

        .catch(error => {
          Swal.fire({
            text: error.message,
            icon: "error",
            buttonsStyling: false,
            heightAuto: false,
            showCancelButton: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn font-weight-bold btn-light-primary",
              cancelButton: "btn font-weight-bold btn-default"
            }
          });
        })

        .finally(() => {
          this.uploading = false;
        });
    },
    removeAvatar() {
      this.$store.dispatch([REMOVE_AVATAR], this.currentUser.ID);
    }
  }
};
</script>
